import React, { useState } from "react"
import { css } from "@emotion/core"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import LocalizedLink from "../utils/localized-link"
import LangSwitch from "../layout/lang-switch"

const NavItem = ({ children }) => (
  <div
    css={css`
      margin: 5px 10px;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 3px;
      display: flex;
      justify-content: center;

      :hover {
        color: #940505;
      }

      :lang(ar) {
        margin: 3px 10px;
        font-size: 18px;
      }
    `}
  >
    {children}
  </div>
)

NavItem.propTypes = {
  children: PropTypes.element.isRequired,
}

const Nav = () => {
  const [show, setShow] = useState(false)

  return (
    <nav
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #c5afaf;

        > div:first-of-type {
          display: none;
        }

        @media (max-width: 640px) {
          background-color: black;

          > div:first-of-type {
            display: flex;
          }
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          display: none;
          position: relative;

          @media (max-width: 640px) {
            background-color: black;
            padding: 5px 0px;

            > div:first-of-type {
              display: flex;
            }
          }
        `}
      >
        <NavItem>
          <button type="button" onClick={() => setShow(!show)}>
            {show ? (
              <FormattedMessage id="nav.close" />
            ) : (
              <FormattedMessage id="nav.menu" />
            )}
          </button>
        </NavItem>
        <div
          css={css`
            padding: 0 8px;
            position: absolute;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            right: 0px;
            top: 0px;
          `}
        >
          <LangSwitch />
        </div>
      </div>
      <ul
        css={css`
          padding: 30px 0px;
          margin: 0px auto;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          overflow: hidden;
          box-sizing: border-box;
          width: 80%;

          @media (max-width: 640px) {
            height: ${show ? "auto" : "0px"};
            display: ${show ? "block" : "none"};
            flex-direction: column;
            padding: 0px;
            padding-bottom: 2px;
          }
        `}
      >
        <li>
          <NavItem>
            <LocalizedLink to="/" textId="nav.home" />
          </NavItem>
        </li>
        <li>
          <NavItem>
            <LocalizedLink to="/bio" textId="nav.bio" />
          </NavItem>
        </li>
        <li>
          <NavItem>
            <LocalizedLink to="/initiative" textId="nav.initiative" />
          </NavItem>
        </li>
        <li>
          <NavItem>
            <LocalizedLink to="/logo" textId="nav.logo" />
          </NavItem>
        </li>
        <li>
          <NavItem>
            <LocalizedLink to="/events" textId="nav.events" />
          </NavItem>
        </li>
        <li>
          <NavItem>
            <LocalizedLink to="/projects" textId="nav.projects" />
          </NavItem>
        </li>
        <li>
          <NavItem>
            <LocalizedLink to="/videos" textId="nav.videos" />
          </NavItem>
        </li>
        <li>
          <NavItem>
            <LocalizedLink to="/donate" textId="nav.donate" />
          </NavItem>
        </li>
        <li>
          <NavItem>
            <LocalizedLink to="/contact" textId="nav.contact" />
          </NavItem>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
