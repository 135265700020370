import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "react-intl"

import Header from "./header"
import Footer from "./footer"
import LangSwitch from "./layout/lang-switch"
import "./layout.css"

const Layout = ({ children }) => {
  const intl = useIntl()
  const lang = intl.locale

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      dir={lang === "ar" ? "rtl" : ""}
      lang={lang}
      css={css`
        width: 100%;
        position: relative; // needed for absolute element
        min-height: 100vh;
        display: flex;
        flex-direction: column;
      `}
    >
      <div // overlay
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: -1000;
          //background-color: rgba(0, 0, 0, 0.53);
        `}
      />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        css={css`
          position: absolute;
          right: 20px;
          top: 20px;
          @media (max-width: 640px) {
            display: none;
          }
        `}
      >
        <LangSwitch />
      </div>
      <div
        css={css`
          margin: 0 auto;
          width: 95%;
          max-width: 850px;
          //padding: 0 1.0875rem 1.45rem;
          display: flex;
          flex-direction: column;

          main {
            width: 100%;
          }
        `}
      >
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
