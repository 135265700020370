import React from "react"
import { useIntl } from "react-intl"
import { useLocation } from "@reach/router"
import { css } from "@emotion/core"
import { Link } from "gatsby" // TODO: switch to LocalizedLink here

const LangSwitch = () => {
  const intl = useIntl()
  const lang = intl.locale
  const location = useLocation()

  return (
    <Link
      to={
        lang === "en"
          ? `/ar${location.pathname}`
          : location.pathname.replace("/ar", "")
      }
    >
      <span
        css={css`
          font-size: 25px;
          color: white;
          letter-spacing: 0.08em;
          font-family: "Nunito Sans";

          // the lang selector is reversed for the switch
          :lang(en) {
            font-size: 20px;
            line-height: 0.5em;
            color: white;
            margin-right: 10px;
            letter-spacing: 0em;
            font-family: Cairo;
          }

          :hover {
            color: #940505;
          }

          @media (max-width: 640px) {
            font-size: 20px;

            :lang(en) {
              font-size: 15px;
            }
          }
        `}
      >
        {lang === "en" ? `عربي` : "EN"}
      </span>
    </Link>
  )
}

export default LangSwitch
