import React from "react"
import { css } from "@emotion/core"
import { FaInstagram, FaFacebook } from "react-icons/fa"

const Footer = () => (
  <footer
    dir="ltr"
    css={css`
      flex-direction: column;
      width: 100%;
      color: #fac1c2;
      padding: 15px 0px;
      font-weight: 700;
      margin-top: auto;
      font-size: 14px;

      > div {
        margin: 5px 0px;
      }
    `}
  >
    <div
      css={css`
        display: flex;
        justify-content: center;
        text-align: center !important;
      `}
    >
      Copyright © 2017-2020 Syrian Music Preservation Initiative Corp. 501
      (c)(3) not-for-profit organization
    </div>
    <div
      css={css`
        font-size: 28px;
        display: flex;
        justify-content: center;
        padding-top: 10px;

        svg {
          margin: 0px 5px;
          color: #f8f2f2;
          opacity: 0.5;
          transition: opacity 100ms linear;

          :hover {
            opacity: 0.8;
          }
        }
      `}
    >
      <a href="https://www.instagram.com/maestro__md/">
        <FaInstagram />
      </a>
      <a href="https://www.facebook.com/takhtalnagham/">
        <FaFacebook />
      </a>
    </div>
  </footer>
)

export default Footer
