import React from "react"
import { css } from "@emotion/core"
import PropTypes from "prop-types"
import Nav from "./header/nav"
import LocalizedLink from "./utils/localized-link"

const NavItem = ({ children }) => (
  <div
    css={css`
      margin: 5px 10px;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 3px;
      display: flex;
      justify-content: center;

      :hover {
        color: #940505;
      }

      :lang(ar) {
        margin: 3px 10px;
        font-size: 18px;
      }
    `}
  >
    {children}
  </div>
)

NavItem.propTypes = {
  children: PropTypes.element.isRequired,
}

const Header = () => (
  <header
    css={css`
      margin-bottom: 1.45rem;
      color: #c5afaf;
    `}
  >
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
      `}
    >
      <Nav />

      <h1
        css={css`
          text-align: center !important;
          margin: 10px auto;
          color: #c5afaf;
          font-size: 33px;
          font-weight: 400;
        `}
      >
        <LocalizedLink to="/" textId="title" useActiveStyle={false} />
      </h1>
    </div>
  </header>
)

export default Header
